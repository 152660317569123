// Navbar

.menu-overlay {

    .menu-inner {
        position: relative;
        height: 100%;
        padding-bottom: 60px;
        overflow-x: hidden;
        overflow-y: auto;

        @include media-breakpoint-up(lg) {
            padding-bottom: 0;
            padding-left: 90px;
            padding-right: 360px;
        }

        @include media-breakpoint-up(xl) {
            padding-right: 460px;
        }

        > nav {
            @include media-breakpoint-up(lg) {
                display: flex;
                height: 100%;
                align-items: center;
            }

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                display: block;
            }
        }

        .dropdown-toggle {
            white-space:normal
        }
    }


    .navbar-nav {
        @include media-breakpoint-down(md) {
            padding-bottom: 2rem;
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin: 0 auto;
            max-width: 920px;

            > .nav-item {
                flex-basis: calc(100% / 2);
                width: 50%;

                a {
                    white-space: inherit;
                }
            }
        }

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            margin: 5rem auto 0;
        }

    }

    .first-level {
        position: relative;
        > .nav-link {
            font-family: $font-family-serif;
            font-weight: $font-weight-normal;
            line-height: 1.1;
        }

        @include media-breakpoint-down(md) {
            > .nav-link {
                font-size: 1.75rem;
                border-bottom: 1px solid $gray-100;

                > .svg-icon {
                    font-size: 1.25rem;
                    margin-left: 0.5rem;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            margin-top: 2rem;

            &:nth-child(1),
            &:nth-child(2) {
                margin-top: 0;
            }

            > .nav-link {
                font-size: 1.875rem;

                > .svg-icon {
                    display: none;
                }
            }
        }
    }

    .second-level {
        > .nav-link {
            font-size: 1.0625rem;
        }

        @include media-breakpoint-down(md) {
            > .nav-link {
                padding-left: ($nav-link-padding-x)*2;
            }
        }
    }

    .dropdown-menu {
        position: static;
        float: none;
        width: 100%;
        margin: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid $gray-100;
        background-color: transparent;

        @include media-breakpoint-up(lg) {
            display: block!important;
            border: 0;
        }
    }
}

.navbar-nav {
    @include list-unstyled();
    margin: 0;
}

.nav-link {
    position: relative;
    display: block;
    line-height: 1;
    transition: all 0.2s;
    color: $dark;

    &:hover {
        color: darken($dark, 15%);
    }

    &:focus {
        outline: 1px dotted;
        outline-offset: -2px;
    }

    @include media-breakpoint-down(md) {
        padding: $nav-link-padding-y $nav-link-padding-x;

        &.active {
            background-color: $primary;
            color: $white;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: $nav-link-padding-y-lg $nav-link-padding-x;

        &.active,
        &:hover {
            color: $primary;
        }
    }
}

.menu-open {
    overflow: hidden;
}

.scrolled,
.menu-open {
    .navbar-toggler .navbar-toggler-text {
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: visibility 0s 0.2s, opacity 0.2s;
    }
}
