
.infoteaser-btn {
    position: fixed;
    right: 10px;
    bottom: 70px;
    display: block;
    width: 48px;
    height: 48px;
    padding: 10px;
    background-color: $primary;
    border: 0;
    border-radius: 24px;
    color: $white;
    box-shadow: 2px 2px 4px 2px rgba($black, 0.15);
    z-index:($zindex-menu) - 2;
    appearance: none;
    pointer-events: auto;
    transition: background-color 0.15s ease-in-out;

    @include hover {
        background-color: $primary-dark;
    }

    &:focus,
    &.focus {
        outline-color: $gray-400;
    }

    > svg {
        width: 100%;
        height: 100%;
    }

    @include media-breakpoint-up(lg) {
        width: 68px;
        height: 68px;
        padding: 14px;
        border-radius: 34px;
        bottom: 10px;
    }
}

.infoteaser-items-wrapper {
    position: fixed;
    display: flex;
    align-items: flex-end;
    top: 10px;
    right: 10px;
    left: 10px;
    bottom: 128px;
    z-index: ($zindex-menu) - 2;
    pointer-events: none;

    &.show-it {
        .infoteaser-inner {
            animation-name: infoIn;
            animation-duration: 250ms;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
    }

    &.hide-it {
        .infoteaser-inner {
            animation-name: infoOut;
            animation-duration: 250ms;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
    }


    .infoteaser-inner {
        position: relative;
        padding: 4rem 1.25rem 1.25rem;
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 2px 2px 4px 2px rgba($black, 0.15);
        background-color: $white;
        pointer-events: auto;
    }

    @include media-breakpoint-up(sm) {
        left: auto;
        width: 450px;
    }

    @include media-breakpoint-up(lg) {
        right: 40px;
        bottom: 98px;

        .infoteaser-inner {
            padding: 4rem 2.625rem 2.625rem;
        }
    }
}

.infoteaser-title {
    font-size: 2.188rem;
}

.infoteaser-close {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 26px;
    height: 26px;
    display: block;
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;


    &:focus,
    &.focus {
        outline-color: $gray-400;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 12px;
        top: 0;
        height: 26px;
        width: 1px;
        background-color: $dark;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

@keyframes infoIn{
    0%{
        opacity: 0;
        transform: scale(0.9) translate3d(1.5rem, 0.5rem, 0);
    }
    100%{
        opacity: 1;
        transform: scale(1) translate3d(0, 0, 0);
    }
}

@keyframes infoOut{
    0%{
        opacity: 1;
        transform: scale(1) translate3d(0, 0, 0);

    }
    100%{
        opacity: 0;
        transform: scale(0.9) translate3d(1.5rem, 0.5rem, 0);
    }
}
