// Gallery

.gallery-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
}
.gallery-item {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;

    img,
    picture {
        /*width: 100%;*/
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
    }

    figure {
        margin-bottom: $grid-gutter-width;
    }
}


// Gallery Item Sizes

.gallery-item-size-1 {
    width: 100%;
}
.gallery-item-size-2 {
    width: 100%;
    @include media-breakpoint-up(sm) {
        width: calc(100% / 2);
    }
}
.gallery-item-size-3 {
    width: 100%;
    @include media-breakpoint-up(sm) {
        width: calc(99.9% / 3);
    }
}

.gallery-item-size-4 {
    width: 100%;
    @include media-breakpoint-up(sm) {
        width: calc(100% / 4);
    }
}

