// Textpic and Textmedia

.textmedia,
.textpic {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1 * ($grid-gutter-width / 2);
    margin-right: -1 * ($grid-gutter-width / 2);
}

.textmedia-item,
.textpic-item {
    width: 100%;
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
}

.textmedia-text,
.textpic-text {
    > *:last-child {
        margin-bottom: 0;
    }
}

.textmedia-below,
.textpic-below,
.textmedia-right ,
.textpic-right{
    .textmedia-text,
    .textpic-text {
        margin-bottom: $spacer * 3;
    }
}

@include media-breakpoint-up(md) {
    .textmedia-right,
    .textmedia-left,
    .textpic-right,
    .textpic-left {
        .textmedia-item,
        .textpic-item {
            margin-bottom: 0;
            width: 50%;
        }
    }
}

.textmedia-right,
.textpic-right {
    .textmedia-item,
    .textpic-item {
        &.textmedia-text,
        &.textpic-text {
            @include media-breakpoint-up(lg) {
                padding-right: calc((#{$grid-gutter-width} / 2) + 1rem);
            }

            @include media-breakpoint-up(xl) {
                padding-right: calc((#{$grid-gutter-width} / 2) + 2rem);
            }
        }
    }
}

.textmedia-left,
.textpic-left {
    .textmedia-item,
    .textpic-item {
        &.textmedia-text,
        &.textpic-text {
            @include media-breakpoint-up(lg) {
                padding-left: calc((#{$grid-gutter-width} / 2) + 0.625rem);
            }

            @include media-breakpoint-up(xl) {
                padding-left: calc((#{$grid-gutter-width} / 2) + 1.25rem);
            }
        }
    }
}

