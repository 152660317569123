@import "variables";

//Bootstrap Required
@import "../../Contrib/Bootstrap/scss/functions";
@import "../../Contrib/Bootstrap/scss/variables";
@import "../../Contrib/Bootstrap/scss/mixins";

$colors: map-remove($colors, "blue", "indigo", "purple", "pink", "red", "orange", "yellow", "green", "teal", "cyan", "gray", "gray-dark");
$theme-colors: map-remove($theme-colors, "primary", "secondary", "success", "info", "warning", "danger", "light", "dark");

//Bootstrap Optional
//@import "../../Contrib/Bootstrap/scss/type" --> custom;
@import "../../Contrib/Bootstrap/scss/images";
//@import "../../Contrib/Bootstrap/scss/code";
//@import "../../Contrib/Bootstrap/scss/grid";
@import "../../Contrib/Bootstrap/scss/tables";
//@import "../../Contrib/Bootstrap/scss/buttons"; --> custom
@import "../../Contrib/Bootstrap/scss/transitions";
@import "../../Contrib/Bootstrap/scss/dropdown";
//@import "../../Contrib/Bootstrap/scss/button-group";
//@import "../../Contrib/Bootstrap/scss/input-group";
//@import "../../Contrib/Bootstrap/scss/custom-forms";
@import "../../Contrib/Bootstrap/scss/nav";
//@import "../../Contrib/Bootstrap/scss/navbar";
//@import "../../Contrib/Bootstrap/scss/card";
//@import "../../Contrib/Bootstrap/scss/breadcrumb";
//@import "../../Contrib/Bootstrap/scss/pagination";
//@import "../../Contrib/Bootstrap/scss/badge";
//@import "../../Contrib/Bootstrap/scss/jumbotron";
//@import "../../Contrib/Bootstrap/scss/alert";
//@import "../../Contrib/Bootstrap/scss/progress";
//@import "../../Contrib/Bootstrap/scss/media";
//@import "../../Contrib/Bootstrap/scss/list-group";
//@import "../../Contrib/Bootstrap/scss/close";
//@import "../../Contrib/Bootstrap/scss/toasts";
//@import "../../Contrib/Bootstrap/scss/modal";
//@import "../../Contrib/Bootstrap/scss/tooltip";
//@import "../../Contrib/Bootstrap/scss/popover";
//@import "../../Contrib/Bootstrap/scss/carousel";
//@import "../../Contrib/Bootstrap/scss/spinners";
//@import "../../Contrib/Bootstrap/scss/utilities";
@import "../../Contrib/Bootstrap/scss/print";

// Custom Styles
@import "navbar";
@import "bsbuttons";
@import "bsutilities";
@import "frame";
@import "textpic";
@import "gallery";
@import "magnific";
@import "boxes";
@import "infoteaser";

// Text selection
::selection {
    background: $selection-bg; /* WebKit/Blink Browsers */
    color: $selection-color;
}

::-moz-selection {
    background: $selection-bg; /* Gecko Browsers */
    color: $selection-color;
}

body {
    font-size: $font-size-base;

    @include media-breakpoint-up(xl) {
        font-size: $font-size-base-xl;
    }
}

ul li:not(.nav-item):not(.list-item):not(.page-item):not(.splide__slide) {
    position: relative;
    line-height: 1.563rem;
    padding-left: 10px;
    padding-bottom: 8px;

    &:last-child {
        padding-bottom: 0;
    }

    &::before {
        content: "\2022";
        color: $bullet-color;
        font-weight: 700;
        font-size: 2.5rem;
        display: inline-block;
        width: 1rem;
        left: 0;
        margin-left: -1rem;
        position: absolute;
    }
}

//Datareporter
#dr_cookie_banner_container {
    ul li {
        line-height: 30px;
        padding-left: 0;

        &:before {
            content: '';
        }
    }
}

.cc-tag-placeholder-video, .cc-tag-placeholder-calendar {
    background-color: $gray-400 !important;
}


// Skiplinks
.skiplinks {
    .sr-only {
        &:focus {
            display: inline-block;
            padding: 0.5rem;
            width: auto;
            height: auto;
            overflow: visible;
            clip: auto;
            white-space: normal;
            background-color: $white;
            z-index: $zindex-skiplinks;
        }
    }
}

.header-caption {
    &.animated {
        animation: fadeInHeader 1.4s linear 0.5s forwards;
    }
}

// Layout
.container {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

//News List View
.news-single {

    .elements-wrapper {
        .frame {
            margin-top: 2rem;
            margin-bottom: 0;
        }
    }

    .news-detail-lead,
    .news-detail-gallery {
        margin-top: 2rem;
    }

    .news-detail-text {
        margin-top: 1.5rem;
    }

}
// Footer
.footer {
    .footer-content {
        padding-top: 4.5rem;
        padding-left: 7px;
        padding-right: 7px;
        padding-bottom:  8.75rem;

        @include media-breakpoint-up(md) {
            padding-bottom:  5.125rem;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 5.375rem;
            padding-bottom:  6rem;
        }
    }

    .h2 {
        padding-bottom: 0;
        &:after {
            border-bottom: 0;
        }
    }

    &-nav {
        .list-item + .list-item {
            margin-top: 0.75rem;
        }
    }

    &-buttons {
        @include media-breakpoint-up(xl) {
            .btn {
                min-width: 13.875rem;
            }
        }
    }

    .footer-phone {
        font-size: 1.125rem;
    }
}

.figure-caption {
    margin-top: 0.25rem;
    text-align: center;
}

@include media-breakpoint-up(md) {
    .frame-default.off-screen {
        .frame-inner {
            transition: 0.8s ease;
            opacity: 0;
            transform: translate(0, 120px);
        }

        &.animated {
            .frame-inner {
                transform: translate(0, 0);
                opacity: 1;
            }
        }
    }
}

@keyframes bounceScrollDown {
    0%, 100% {
        transform: translateX(0);
    }
    44% {
        transform: translateX(-12px);
    }
}

@keyframes bounceRight {
    0%, 100% {
        transform: translateX(0);
    }
    56% {
        transform: translateX(12px);
    }
}

@keyframes fadeInHeader {
    0% {
        opacity: 0;
        transform: translate(1.5rem, 0.5rem);
    }
    80% {
        transform: translate(0, 0);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}
