.box-link {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    color: $white;
    background-color: $dark;
    font-family: $font-family-serif;
    font-size: 2.625rem;
    line-height: 1.2;

    .box-link-inner {
        position: absolute;
        display: flex;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: center;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($black, 0.25);
            z-index: 1;
        }
    }

    .box-link-bg  {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        transition: all 0.4s;
    }

    .box-link-text {
        position: relative;
        z-index: 2;
        text-align: center;
    }

    &:hover {
        color: $white;
        text-decoration: none;

        .box-link-bg {
            transform: scale(1.05);
        }
    }

    &:focus {
        color: $white;
        text-decoration: none;
    }

    @include media-breakpoint-between(md, lg) {
        font-size: 1.75rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 3rem;
    }
}

.webp {
    .box-link-one {
        .box-link-bg {
            background-image: url("../../Images/tbe_box-1.webp");
        }
    }

    .box-link-two {
        .box-link-bg {
            background-image: url("../../Images/tbe_box-2.webp");
        }
    }

    .box-link-three {
        .box-link-bg {
            background-image: url("../../Images/tbe_box-3.webp");
        }
    }

    .box-link-four {
        .box-link-bg {
            background-image: url("../../Images/tbe_box-4.webp");
        }
    }
}

.no-webp {
    .box-link-one {
        .box-link-bg {
            background-image: url("../../Images/tbe_box-1.jpg");
        }
    }

    .box-link-two {
        .box-link-bg {
            background-image: url("../../Images/tbe_box-2.jpg");
        }
    }

    .box-link-three {
        .box-link-bg {
            background-image: url("../../Images/tbe_box-3.jpg");
        }
    }

    .box-link-four {
        .box-link-bg {
            background-image: url("../../Images/tbe_box-4.jpg");
        }
    }
}

.content-boxes-wrapper {
    .box-link {
        height: 0;
        padding-bottom: 75%;
    }
}

@include media-breakpoint-up(lg) {
    .menu-overlay {
        .menu-boxes-wrapper {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            width: 360px;
            .box-link {
                height: calc(100% / 4);
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .menu-overlay {
        .menu-boxes-wrapper {
            width: 460px;
        }
    }
}
